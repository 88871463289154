import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container } from "../components/Container";

const formContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
  padding: "20px 0",
};
const Contact = () => (
  <Layout heroSection={false} contactFooter={false}>
    <SEO title="Contact" />
    <Container>
      <div style={formContainer}>
        <form
          className="flexForm"
          action="thank-you"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          name="contact"
        >
          <noscript>
            <p>This form won’t work with Javascript disabled</p>
          </noscript>
          <input type="hidden" name="form-name" value={"contact"} />
          <p className="hidden" hidden>
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
          <h1>Let's talk</h1>
          <p>
            Have an interesting project idea that you wish to see come to life?
            Feel free to contact me.
          </p>
          <label className="sr-only" htmlFor="name">
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Your name"
            autoComplete="name"
            required
          />
          <label className="sr-only" htmlFor="email">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            placeholder="Your email"
            required
          />
          <label className="sr-only" htmlFor="message">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            placeholder="Your message"
            required
            rows={4}
          />
          <button>Send</button>
        </form>
      </div>
    </Container>
  </Layout>
);

export default Contact;
